.contact-container {
  padding-top: 60px;
  padding-bottom: 60px;
}

h2 {
  margin-bottom: 20px;
  font-size: 2rem;
}

.form-control {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1.1rem;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.alert {
  margin-bottom: 20px;
}

button {
  font-size: 1.1rem;
  padding: 10px 0;
}

.character-counter {
  display: block;
  bottom: 10px;
  right: 15px;
  font-size: 0.875rem;
  color: #6c757d;
}

.custom-file-input {
  display: none;
}

.custom-file-label {
  display: inline-block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  cursor: pointer;
  text-align: left;
}

.custom-file-label::after {
  content: "Browse";
  display: inline-block;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
  cursor: pointer;
}

.custom-file-label:hover {
  background-color: #d6d8db;
}