/* Header.css */

.ml-3 {
  margin-left: 1rem !important;
}

.navbar {
  padding: 0.5rem 1rem; /* Adjust padding for the Navbar */
}

.nav-link {
  color: #fff; /* White color for the nav links */
  transition: color 0.3s; /* Smooth transition for hover effect */
}

.nav-link:hover, .nav-link:focus {
  color: #ccc; /* Lighter color on hover/focus */
  text-decoration: none; /* Removes underline on hover/focus */
}

.navbar-brand {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
}

.navbar-brand.pressed {
  transform: scale(0.95); /* Slightly reduce the size to create a pressed effect */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3); /* Adds an inset shadow to give the impression of depth */
}

.navbar-brand:hover, .navbar-brand:focus {
  color: #ccc; /* Lighter color on hover/focus */
  text-decoration: none; /* Removes underline on hover/focus */
}

.navbar-toggle {
  border-color: #fff; /* Sets the toggle button border color */
}

/* Ensure that the toggle and collapse work correctly on small screens */
@media (max-width: 991px) {
  .navbar-collapse {
    background-color: #000; /* Background color for the collapsed menu */
  }
}
