/* Existing styles */
.contact-container {
  padding-top: 80px;
  padding-bottom: 60px;
}

h2 {
  margin-bottom: 20px; /* Increased bottom margin */
  font-size: 2rem; /* Increased font size */
}

.form-control {
  margin-bottom: 15px;
  padding: 10px;
  font-size: 1.1rem;
}

.mb-3 {
  margin-bottom: 1.5rem !important; /* 1.5rem bottom margin */
}

.mb-4 {
  margin-bottom: 2rem !important; /* 2rem bottom margin */
}

/* Added spacing for alerts */
.alert {
  margin-bottom: 20px;
}

button {
  font-size: 1.1rem;
  padding: 10px 0;
}

.character-counter {
  display: block;  
  bottom: 10px;
  right: 15px;
  font-size: 0.875rem;
  color: #6c757d;
}

.custom-file-input {
  display: none;
}

.custom-file-label {
  display: inline-block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  cursor: pointer;
  text-align: left;
}

.custom-file-label::after {
  content: "Browse";
  display: inline-block;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
  cursor: pointer;
}

.custom-file-label:hover {
  background-color: #d6d8db;
}

.fade-alert {
  transition: opacity 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
}

.alert-container {
  transition: height 0.5s ease-in-out;
}

.alert-container.hide {
  height: 0;
  overflow: hidden;
}

.alert-container.show {
  height: auto;
}

/* Added/Modified styles for About page */
.profile-pic {
  width: 100%; /* 25% larger */
  height: auto;
  max-width: 200px; /* Adjust this to control the max size */
  border-radius: 50%; /* Ensures the image is circular */
  object-fit: cover; /* Ensures the image covers the area */
  padding: 10px;
  margin: 0 auto; /* Center the image horizontally */
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.profile-pic:active {
  transform: scale(0.95); /* Slightly reduce the size to create a pressed effect */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3); /* Adds an inset shadow to give the impression of depth */
}

.image-container {
  padding: 20px; /* Adjust padding to ensure spacing is consistent */
  text-align: center;
}

.about-card {
  background-color: #f8f9fa;
  border: none;
  border-radius: 15px;
  overflow: hidden;
}

.about-text, .resume-text {
  font-size: 1.1rem; /* Slightly larger text */
  line-height: 1.6; /* Increased line spacing */
  color: #333; /* Darker color for better readability */
  padding: 10px 15px; /* Add padding inside the text block */
}

.resume-text h5, .about-text h5 {
  font-size: 1.5rem; /* Larger font for headings */
  margin-bottom: 10px; /* Space below the heading */
}

.resume-text h6, .about-text h6 {
  font-size: 1.2rem; /* Slightly smaller font for subheadings */
  margin-top: 20px; /* Space above the subheading */
  margin-bottom: 10px; /* Space below the subheading */
  font-weight: bold;
}

.resume-text p, .about-text p {
  font-size: 1rem; /* Standard font size for paragraphs */
  margin-bottom: 10px; /* Space below paragraphs */
}

.resume-text ul, .about-text ul {
  padding-left: 20px; /* Indent the list items */
  list-style-type: disc; /* Use disc bullets for the list */
  margin-bottom: 15px; /* Space below the list */
}

.resume-text ul li, .about-text ul li {
  margin-bottom: 5px; /* Space between list items */
}

.custom-tabs .nav-tabs {
  margin-bottom: 1rem;
  border-bottom: 2px solid #dee2e6;
}

.custom-tabs .nav-item {
  margin-bottom: -1px;
}

.custom-tabs .nav-link {
  border: 1px solid transparent;
  border-radius: .25rem .25rem 0 0;
  color: #6c757d !important;
}

.custom-tabs .nav-link.active,
.custom-tabs .nav-link:focus,
.custom-tabs .nav-link:hover {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #dee2e6 #dee2e6 #fff !important;
}

.custom-tabs .nav-link.disabled {
  color: #6c757d !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.fancy-alert {
  background: linear-gradient(135deg, #d9edf7, #f7f7f7); /* Soft gradient for the alert background */
  border-left: 5px solid #007bff; /* Left border for a stylish look */
  border-radius: 5px; /* Slightly rounded corners */
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  font-family: inherit; /* Use the same font as the rest of the app */
}
