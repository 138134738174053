html, body {
  height: 100%;
  margin: 0;
}

body {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px; /* Ensure there's space for the footer */
  transition: margin-top 0.3s ease-in-out;
}

.navbar-expanded {
  margin-top: 100px; /* Adjust this value based on the navbar height */
}

/* Fade in/out transitions */
.fade-in {
  opacity: 1 !important;
}

.fade-out {
  opacity: 0 !important;
}

/* Transition classes for the route components */
.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}
.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.fade-exit {
  opacity: 1;
  transform: translateY(0);
}
.fade-exit-active {
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 300ms, transform 300ms;
}
